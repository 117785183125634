body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.modal-60w {
  max-width: 60vw !important;
}

@font-face {
  font-family: 'NetflixSans_W_Bd';
  src: local('NetflixSans_W_Bd'), url(./fonts/webfontkit-netflix/NetflixSans_W_Bd.woff) format('woff');
}

@font-face {
  font-family: 'NetflixSans_W_BdIt';
  src: local('NetflixSans_W_BdIt'), url(./fonts/webfontkit-netflix/NetflixSans_W_BdIt.woff) format('woff');
}

@font-face {
  font-family: 'NotoSansKR-Bold';
  src: local('NotoSansKR-Bold'), url(./fonts/korean/NotoSansKR-Bold.woff) format('woff');
}

@font-face {
  font-family: 'NanumGothicBd';
  src: local('NanumGothicBd'), url(./fonts/korean/NanumGothicBd.woff) format('woff');
}

@font-face {
  font-family: 'NotoSansJP-Bold';
  src: local('NotoSansJP-Bold'), url(./fonts/japanese/NotoSansJP-Bold.woff) format('woff');
}

@font-face {
  font-family: 'NotoSans-Bold';
  src: local('NotoSans-Bold'), url(./fonts/english/NotoSans-Bold.woff) format('woff');
}